<template>
  <div
    class="player-content"
    :class="{
      'card-past': liveStatus === 'PASADO',
      'card-future': liveStatus === 'FUTURO',
    }"
  >
    <div class="bg" :style="getPoster"></div>
    <div class="card-channel">
      <span title="FOX SPORTS 3" class="channel-logo">
        <img
          v-if="program.channel.logo"
          :src="program.channel.logo"
          :alt="program.channel.name"
          width="40"
          height="40"
          class="img-logo"
        />
      </span>
      <div class="card-tv-channel-number">
        <strong class="card-tv-channel-number-input"
          >CH {{ program.channel.lcn }}</strong
        >
      </div>
    </div>
    <div class="card-content">
      <div class="card-img-overlay">
        <a
          v-if="btnPlayState == 'pause-actual'"
          v-on:click.prevent="getPlayer().onControlPlayPause()"
          href="#play-this"
          title="Pausar esto"
          class="btn btn-play"
        >
          <i class="zmdi zmdi-pause" aria-hidden="true"></i>
        </a>
        <a
          v-else-if="btnPlayState == 'play-actual'"
          v-on:click.prevent="getPlayer().onControlPlayPause()"
          href="#play-this"
          title="Reproducir esto"
          class="btn btn-play"
        >
          <i class="zmdi zmdi-play" aria-hidden="true"></i>
        </a>
        <a
          v-else-if="btnPlayState != 'futuro'"
          v-on:click.prevent="onPlay()"
          href="#play-this"
          title="Reproducir esto"
          class="btn btn-play"
        >
          <i class="zmdi zmdi-play" aria-hidden="true"></i>
        </a>
        <!-- <a
          v-if="program.status != 'PASADO'"
          v-on:click.prevent="recordOptions.show = !recordOptions.show"
          href="#"
          class="btn btn-record excludeDropdown"
          title="Grabar este programa"
        >
          <i class="zmdi zmdi-dot-circle"></i>
          <span class="sr-only">Grabar este programa</span>
        </a> -->
        <template
          v-if="
            !recordOptions.blocked &&
            recordStatus === 'Planned' &&
            recordOptions.grabado === true
          "
        >
          <a
            v-on:click.prevent="onStopRecording()"
            href="#"
            data-r=""
            class="btn btn-danger btn-record cancelar-grabacion"
            title="Cancelar grabación"
          >
            <i class="zmdi zmdi-time-interval"></i>
            <span class="sr-only">Cancelar grabación</span>
          </a>
        </template>
        <template
          v-else-if="
            !recordOptions.blocked &&
            recordStatus === 'Recording' &&
            recordOptions.grabado === true
          "
        >
          <a
            v-on:click.prevent="onStopRecording()"
            href="#"
            class="btn btn-danger btn-record to-record record-confirmed detener-grabacion"
            title="Detener grabación"
          >
            <i class="zmdi zmdi-dot-circle-alt"></i>
            <span class="sr-only">Detener grabación</span>
          </a>
        </template>
        <template v-else-if="recordOptions.blocked">
          <a
            v-on:click.prevent="function () {}"
            href="#"
            class="btn btn-record disabled opcion-no-disponible"
            title="Opción no disponible temporalmente"
          >
            <i class="zmdi zmdi-dot-circle"></i>
          </a>
        </template>

        <template
          v-if="
            !recordOptions.blocked &&
            recordOptions.grabado === false &&
            program.status != 'PASADO' &&
            recordStatus != 'Planned' &&
            recordStatus != 'Recording'
          "
        >
          <a
            v-on:click.prevent="showRecordModal"
            href="#"
            class="btn btn-record excludeDropdown"
            :class="{ 'to-record': recordOptions.show }"
            title="Programar grabación"
          >
            <i
              class="zmdi"
              :class="
                recordOptions.show ? 'zmdi-time-interval' : 'zmdi-dot-circle'
              "
            ></i>
            <span class="sr-only">Programar grabación</span>
          </a>
        </template>
      </div>
    </div>
    <div class="card-footer">
      <a
        v-on:click.prevent="onMoreInfo()"
        href="#more-info"
        title="Más información"
        class="card-action-secondary btn-showmodal"
      >
        <i class="zmdi zmdi-info-outline"></i>
      </a>
    </div>
    <div class="modal-livetv">
      <ModalRecord 
        :asset="program" 
        :recordOptions="recordOptions" 
        @closeModal="closePopoverRecord"
      />
    </div>
  </div>
</template>

<script>
import * as dateHelper from "@/helpers/dateHelper.js";
import { blockAsset } from "@/helpers/parentalControl.js";

import IMG_NO_POSTER_16_9 from "@/assets/images/no-poster-16-9.png";
import { getStatus } from "@/observables/epg";

const IMG_DEFAULT_ALPHA =
  "a5434fa604af965aa91eb3e32c523ae2f383bfdf6f6cbaa5682ed36a60b5b33e";

export default {
  name: "GuideTradCardTv",

  inheritAttrs: false,

  inject: ["getPlayer", "modalConfirmShow"],

  components: {
    ModalRecord: () => import("@/components/ModalRecord.vue"),
  },

  props: {
    program: {
      type: Object,
      required: false,
      default: () => {
        return {
          title: "Sin información",
          time: Date.now(),
          poster: "",
          channel: {
            logo: "",
            lcn: "",
          },
        };
      },
    },
  },

  data() {
    return {
      btnPlayState: null,
      blockByParentalControl: false,
      recordOptions: tplay.recordOptionsDefault(),
      cpChangedStatus: 1
    };
  },

  mounted() {
    this.$bus.$on("player:state-change", this.onPlayerStateChange);
    window.addEventListener("click", this.closePopoverRecord, false);
    this.$bus.$on("parentalControl.state_changed", this.updateParentalControl);
  },

  beforeDestroy() {
    this.$bus.$off("player:state-change", this.onPlayerStateChange);
    window.removeEventListener("click", this.closePopoverRecord, false);
    this.$bus.$off("parentalControl.state_changed", this.updateParentalControl);
  },

  computed: {
    liveStatus() {
      return getStatus(this.program);
    },
    getPoster() {
      const urlFromAlpha = this.program.poster
        ? this.program.poster.split("/").pop()
        : "";

      this.cpChangedStatus;

      if (blockAsset(this.program) || urlFromAlpha === IMG_DEFAULT_ALPHA) {
        return "background-image: url(" + IMG_NO_POSTER_16_9 + ");";
      } else {
        const poster = this.program.poster
          ? this.program.poster
          : IMG_NO_POSTER_16_9;
        return "background-image: url(" + poster + ");";
      }

      return "background-image: url(" + this.program.poster + ");";
    },
    recordStatus() {
      if (this.program.recordData != undefined) {
        return this.program.recordData;
      } else {
        return this.program.status;
      }
    },
  },

  methods: {
    updateParentalControl() {
      //console.log('parentalControl.state_changed',this.cpChangedStatus);
      this.cpChangedStatus++;
    },
    onMoreInfo: function () {
      let self = this;

      tplay.sendGrafanaEvent(
        tplay.grafana.event.CARD,
        {
          rail_id: "CardGuideTrad",
          rail_title: "CardGuideTrad",
          card_id: "CardGuideTrad",
          screen: self.$route.name,
        },
        tplay.grafana.actions.ENTER
      );

      //this.getFullData(function() {

      if (blockAsset(self.program)) {
        self.$bus.$emit("modal-pin:show", function () {
          self.$bus.$emit("modal-tv:show", {
            data: self.program,
            componentOrigin: "GuideTrad",
          });
        });
      } else {
        self.$bus.$emit("modal-tv:show", {
          data: self.program,
          componentOrigin: "GuideTrad",
        });
      }

      //});
    },
    updateDatosPrograma() {
      const self = this;
      self.blockByParentalControl = blockAsset(self.program);
      self.onPlayerStateChange();
    },
    onPlayerStateChange() {
      if (getStatus(this.program) === "FUTURO") {
        this.btnPlayState = "futuro";
      } else if (
        this.getPlayer().isActive() &&
        this.getPlayer().isAsset(this.program.idAsset)
      ) {
        this.btnPlayState = this.getPlayer().isPlaying()
          ? "pause-actual"
          : "play-actual";
      } else {
        this.btnPlayState = "play-otro";
      }
    },
    onPlay() {
      let self = this;

      tplay.sendGrafanaEvent(
        tplay.grafana.event.CARD,
        {
          rail_id: "CardGuideTrad",
          rail_title: "CardGuideTrad",
          card_id: "CardGuideTrad",
          screen: self.$route.path,
        },
        tplay.grafana.actions.ENTER
      );

      //this.getFullData(function() {

      if (blockAsset(self.program)) {
        self.$bus.$emit("modal-pin:show", function () {
          if (gtag) {
            gtag("event", "Reproducir desde card", {
              event_category: "CardGuideTrad",
              event_action: "Reproducir desde canal",
              event_label:
                "[ " + self.program.channel.name + " ] " + self.program.title,
              value: "0",
            });
            // self.$bus.$emit('player:set-source', self.dataAsset);
          }

          self.$bus.$emit("player:set-source", self.program);
          tplay.sendAudience(self.program, "GuideTrad");
        });
      } else {
        if (gtag) {
          gtag("event", "Reproducir desde card", {
            event_category: "CardGuideTrad",
            event_action: "Reproducir desde canal",
            event_label:
              "[ " + self.program.channel.name + " ] " + self.program.title,
            value: "0",
          });
        }

        self.$bus.$emit("player:set-source", self.program);
        tplay.sendAudience(self.program, "GuideTrad");
      }
      let butttonClose = document.querySelector(".close");
      butttonClose?.click();

      //},function(err){
      //    console.log('getFullData', err);
      //});
    },
    closePopoverRecord(e) {
      const self = this;
      if (e && e.forceCLose) {
        self.recordOptions.show = false;
        return;
      } else if (
        (e.target.className &&
          e.target.className.indexOf("excludeDropdown") !== -1) ||
        (e.target.parentNode &&
          e.target.parentNode.className &&
          e.target.parentNode.className.indexOf("excludeDropdown") !== -1)
      ) {
        return;
      }
      self.recordOptions.show = false;
    },
    onStopRecording() {
      const self = this;
      console.log(111111111111)
      this.modalConfirmShow({
        type: "confirm",
        classType: "danger",
        title: "Confirmá esta acción",
        acceptText:
          `Sí, ` + (this.recordStatus != "Recorded" ? "Cancelar" : "Eliminar"),
        cancelText: "No",
        content:
          `<p>Vas a ` +
          (this.recordStatus != "Recorded" ? "cancelar" : "eliminar") +
          ` la grabación de "` +
          self.program.title +
          `". ¿Estás seguro?</p>`,

        confirm: function (ok) {
          if (ok) {
            tplay.stopRecording(self.program, self.recordOptions, self.$bus);
          }
        },
      });

      let butttonClose = document.querySelector(".close");
      butttonClose?.click();
    },
    showRecordModal() {
      const { recordOptions, program, $bus } = this;
      
      if (blockAsset(program)) {
        $bus.$emit("modal-pin:show", () => {
          recordOptions.show = !recordOptions.show;
        });
      } else {
        recordOptions.show = !recordOptions.show;
      }
    }
  },
  watch: {
    program(value) {
      const self = this;
      if (value.fromEpg) {
        let playerWindow = document.querySelector(".player");
        if (playerWindow.classList.contains("player-embedded")) {
          self.onMoreInfo();
        }
      }

      if (typeof value.grabado !== "function") {
        self.recordOptions.grabado = false;
        console.warn('El campo "grabado" no es una función.');
      } else {
        self.recordOptions.grabado = value.grabado();
      }
    },

    "program.status": function (n, o) {
      if (n != 0) {
        this.updateDatosPrograma();
        //this.onPlayerStateChange();
      }
    },
    // blockByParentalControl:function(newValue, oldValue){
    //     // aca lo voy a guardar en forma de un objeto
    //     localStorage.setItem('oldvalueParentalControl',oldValue);
    //     localStorage.setItem('newvalueParentalControl',newValue);

    // },
    // chequeo si el zap esta activo y le asigno  a las cards del zap el valor del control parental que poseian antes de desbloqearlos
    // zapflag:function(newvalue, oldvalue){
    //     if (newvalue === true) {
    //         this.blockByParentalControl = localStorage.getItem('oldvalueParentalControl');
    //     }
    // }
  },
};
</script>
