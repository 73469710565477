var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"player-content",class:{
    'card-past': _vm.liveStatus === 'PASADO',
    'card-future': _vm.liveStatus === 'FUTURO',
  }},[_c('div',{staticClass:"bg",style:(_vm.getPoster)}),_c('div',{staticClass:"card-channel"},[_c('span',{staticClass:"channel-logo",attrs:{"title":"FOX SPORTS 3"}},[(_vm.program.channel.logo)?_c('img',{staticClass:"img-logo",attrs:{"src":_vm.program.channel.logo,"alt":_vm.program.channel.name,"width":"40","height":"40"}}):_vm._e()]),_c('div',{staticClass:"card-tv-channel-number"},[_c('strong',{staticClass:"card-tv-channel-number-input"},[_vm._v("CH "+_vm._s(_vm.program.channel.lcn))])])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"card-img-overlay"},[(_vm.btnPlayState == 'pause-actual')?_c('a',{staticClass:"btn btn-play",attrs:{"href":"#play-this","title":"Pausar esto"},on:{"click":function($event){$event.preventDefault();_vm.getPlayer().onControlPlayPause()}}},[_c('i',{staticClass:"zmdi zmdi-pause",attrs:{"aria-hidden":"true"}})]):(_vm.btnPlayState == 'play-actual')?_c('a',{staticClass:"btn btn-play",attrs:{"href":"#play-this","title":"Reproducir esto"},on:{"click":function($event){$event.preventDefault();_vm.getPlayer().onControlPlayPause()}}},[_c('i',{staticClass:"zmdi zmdi-play",attrs:{"aria-hidden":"true"}})]):(_vm.btnPlayState != 'futuro')?_c('a',{staticClass:"btn btn-play",attrs:{"href":"#play-this","title":"Reproducir esto"},on:{"click":function($event){$event.preventDefault();return _vm.onPlay()}}},[_c('i',{staticClass:"zmdi zmdi-play",attrs:{"aria-hidden":"true"}})]):_vm._e(),(
          !_vm.recordOptions.blocked &&
          _vm.recordStatus === 'Planned' &&
          _vm.recordOptions.grabado === true
        )?[_c('a',{staticClass:"btn btn-danger btn-record cancelar-grabacion",attrs:{"href":"#","data-r":"","title":"Cancelar grabación"},on:{"click":function($event){$event.preventDefault();return _vm.onStopRecording()}}},[_c('i',{staticClass:"zmdi zmdi-time-interval"}),_c('span',{staticClass:"sr-only"},[_vm._v("Cancelar grabación")])])]:(
          !_vm.recordOptions.blocked &&
          _vm.recordStatus === 'Recording' &&
          _vm.recordOptions.grabado === true
        )?[_c('a',{staticClass:"btn btn-danger btn-record to-record record-confirmed detener-grabacion",attrs:{"href":"#","title":"Detener grabación"},on:{"click":function($event){$event.preventDefault();return _vm.onStopRecording()}}},[_c('i',{staticClass:"zmdi zmdi-dot-circle-alt"}),_c('span',{staticClass:"sr-only"},[_vm._v("Detener grabación")])])]:(_vm.recordOptions.blocked)?[_c('a',{staticClass:"btn btn-record disabled opcion-no-disponible",attrs:{"href":"#","title":"Opción no disponible temporalmente"},on:{"click":function($event){$event.preventDefault();return (function () {}).apply(null, arguments)}}},[_c('i',{staticClass:"zmdi zmdi-dot-circle"})])]:_vm._e(),(
          !_vm.recordOptions.blocked &&
          _vm.recordOptions.grabado === false &&
          _vm.program.status != 'PASADO' &&
          _vm.recordStatus != 'Planned' &&
          _vm.recordStatus != 'Recording'
        )?[_c('a',{staticClass:"btn btn-record excludeDropdown",class:{ 'to-record': _vm.recordOptions.show },attrs:{"href":"#","title":"Programar grabación"},on:{"click":function($event){$event.preventDefault();return _vm.showRecordModal.apply(null, arguments)}}},[_c('i',{staticClass:"zmdi",class:_vm.recordOptions.show ? 'zmdi-time-interval' : 'zmdi-dot-circle'}),_c('span',{staticClass:"sr-only"},[_vm._v("Programar grabación")])])]:_vm._e()],2)]),_c('div',{staticClass:"card-footer"},[_c('a',{staticClass:"card-action-secondary btn-showmodal",attrs:{"href":"#more-info","title":"Más información"},on:{"click":function($event){$event.preventDefault();return _vm.onMoreInfo()}}},[_c('i',{staticClass:"zmdi zmdi-info-outline"})])]),_c('div',{staticClass:"modal-livetv"},[_c('ModalRecord',{attrs:{"asset":_vm.program,"recordOptions":_vm.recordOptions},on:{"closeModal":_vm.closePopoverRecord}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }